import {API} from '../Constants';

const isDevelopmentMode = process.env.NODE_ENV !== 'production';

const request = (controller, method, data) => {
	return new Promise((resolve, reject) => {
		const url = `${API.url}/${controller}/${method}`;
		const options = {
			method:'POST',
			headers:new Headers({'Authentication':API.key}),
			body: data == null ? null : JSON.stringify(data)
		};
		fetch(url, options)
			.then(res => {
				if (isDevelopmentMode) console.log(res);
				return res.json();
			})
			.then(res => {
				if (isDevelopmentMode) console.log(res);
				if (res.code === 0) resolve(res);
				else reject(res);
			})
			.catch(error => {
				if (isDevelopmentMode) console.log(error);
				reject(error);
		});
	});
};

const get = (controller, data) => request(controller, 'get', data);
const post = (controller, data) => request(controller, 'add', data);
const put = (controller, data) => request(controller, 'update', data);
const remove = (controller, data) => request(controller, 'delete', data);

const Http = {
	get,
	post,
	put,
	remove,
	request
};

export default Http;
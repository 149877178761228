import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// components
import SimpleTemplate from '../../Components/MasterPages/SimpleTemplate';

// styles
import styles from './AboutPayment.module.css';

class AboutPayment extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}
	render() {
		return (
			<SimpleTemplate>
				<div className="container">
					<Link to={'/'}><img src={require('../../Images/icons/back.svg')} className="back" alt="назад" /></Link>
					<h2>Иформация об оплате</h2>
					<div>
						<p>Все платежи в приложении КПК «Единый сберегательный центр» осуществляются с помощью платежной системы Uniteller.</p>
						<h3>Гарантии безопасности</h3>
						<p>Безопасность процессинга Uniteller подтверждена сертификатом стандарта безопасности данных индустрии платежных карт PCI DSS. Надежность сервиса обеспечивается интеллектуальной системой мониторинга мошеннических операций, а также применением 3D Secure - современной технологией безопасности интернет-платежей.</p>
						<p>Данные Вашей карты вводятся на специальной защищенной платежной странице. Передача информации в процессинговую компанию Uniteller происходит с применением технологии шифрования TLS. Дальнейшая передача информации осуществляется по закрытым банковским каналам, имеющим наивысший уровень надежности.</p>
						<p>Uniteller не передает данные Вашей карты магазину и иным третьим лицам!</p>
						<p>Если Ваша карта поддерживает технологию 3D Secure, для осуществления платежа, Вам необходимо будет пройти дополнительную проверку пользователя в банке-эмитенте (банк, который выпустил Вашу карту). Для этого Вы будете направлены на страницу банка, выдавшего карту. Вид проверки зависит от банка. Как правило, это дополнительный пароль, который отправляется в SMS, карта переменных кодов, либо другие способы.</p>
						<p>
							<img src={require('../../Images/payment.png')} alt="" className={styles.payment} />
						</p>
					</div>
				</div>
			</SimpleTemplate>
		);
	}
}

export default AboutPayment;
import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// components
import SimpleTemplate from '../../Components/MasterPages/SimpleTemplate';

// styles
import styles from './About.module.css';

class About extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}
	render() {
		return (
			<SimpleTemplate>
				<div className="container">
					<Link to={'/'}><img src={require('../../Images/icons/back.svg')} className="back" alt="назад" /></Link>
					<h2>О нас</h2>
					<div>
						<p>Кредитный потребительский кооператив «Единый сберегательный центр» (ОГРН 1181690040641 ИНН/КПП 1656102801/165601001) состоит в СРО «Кооперативные Финансы» (протокол № 19/2018 от «12» сентября 2018 г.).</p>
						<p>Услуги предоставляются физическим и юридическим лицам, при условии вступления в члены Кооператива и оплаты паевого и вступительного взноса.</p>
						<p>Сбережения принимаются от физических лиц на срок от 3 до 24 месяцев по ставкам до 8.1% годовых. Максимальный доход до 8.1% в год возможен при размещении личных сбережений на срок от 12 до 24 месяцев по программам «Стабильный» и «Золотой эльдорадо». При досрочном расторжении договора по инициативе пайщика, проценты рассчитываются по ставке 3% годовых на весь период действия договора.</p>
						<h3>Гарантии</h3>
						<p>Размещение личных сбережений в КПК «Единый сберегательный центр» — это удобный и надёжный способ приумножить личный капитал.</p>
						<p>В КПК «Единый сберегательный центр»» действует многоступенчатая система сохранения и защиты личных сбережений:</p>
						<p>
							- Интересы пайщиков защищает Закон «О кредитной кооперации».<br/>
							- Система долгосрочных и краткосрочных займов своей доходностью полностью обеспечивает работу сберегательных программ.<br/>
							- КПК «Единый сберегательный центр» состоит в в СРО «Кооперативные Финансы».<br/>
							- Все сбережения пайщиков застрахованы за счет и по инициативе КПК «Единый сберегательный центр».
						</p>
					</div>
				</div>
			</SimpleTemplate>
		);
	}
}

export default About;
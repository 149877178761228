
const phoneNormalize = (phone) => {
	if (!empty(phone)) {
		phone = phone.replace(/\s+/gi, '');
		phone = phone.replace(/-/gi, '');
		phone = phone.replace(/\(/gi, '');
		phone = phone.replace(/\)/gi, '');
		phone = phone.replace(/\+/gi, '');
		phone = phone.replace(/[^0-9]+/, '');
		if (!empty(phone)) phone = phone.length < 10 ? '' : phone;
		if (!empty(phone) && phone[0] === '8') phone = phone.substr(1);
		if (!empty(phone)) phone = phone[0] !== '7' ? '7' + phone : phone;
	}
	return phone || null;
}

const phoneFormatter = (phone) => empty(phone) ? phone : phone.replace('+', '').replace(/(\d)(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');

const phoneWS = (phone) => phone.slice(1);

const empty = (text) => text === null || text === '' || text.toString().trim() === '';

const moneyFormat = (amount, nofraction) => amount ? parseFloat(amount).toFixed(nofraction === undefined || nofraction === true ? 2 : 0).replace(/(\d)(?=(\d{3})+(?!\d)\.?)/g, '$1 ') : 0

const dateGet = (date, options) => {
	const d = new Date(date*1000);
	const months = {
		short:	['янв','фев','мар','апр','май','июн','июл','авг','сен','окт','ноя','дек'],
		full:	['января','февраля','марта','апреля','мая','июня','июля','августа','сентября','октября','ноября','декабря']
	};
	options = options || {};
	let m = d.getMonth(), day = d.getDate(), separator = '.', yearLetter = '';
	if (options.showMonthFullName) {
		m = months.full[m];
		separator = ' ';
	}
	else if (options.showMonthShortName) {
		m = months.short[m];
		separator = ' ';
	}
	else {
		m = m + 1
		m = m > 9 ? m : `0${m}`;
		day = day > 9 ? day : `0${day}`;
	}
	let time = ''
	if (options.showTime) {
		let h = d.getHours(), min = d.getMinutes(), s = d.getSeconds()
		h = h === 0 ? '00' : h
		min = min > 9 ? min : `0${min}`
		s = s > 9 ? s : `0${s}`
		s = options.showSeconds ? `:${s}` : ''
		time = `, ${h}:${min}${s}`
	}

	if (options.addYearLetter) yearLetter = ' г.';
	if (options.isJson) return {month:m,year:d.getFullYear(),day:day};
	return `${day}${separator}${m}${separator}${d.getFullYear()}${yearLetter}${time}`;
}

const dateNow = () => dateRound(new Date().getTime());

const timestampGet = (date) => {
	if (empty(date)) return 0;
	let d = date.split('.');
	if (d.length !== 3) return 0;
	return dateRound(new Date(d[2], parseInt(d[1])-1, d[0]).getTime());
}

const timestampConvert = (ts) => {
	const date = new Date(ts * 1000);
	return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2) + 'T' +  ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2);
}

const dateSegmentsGet = (date) => {
	date = date || new Date();
	return {
		year:date.getFullYear(),
		month:date.getMonth() + 1,
		day:date.getDate(),
		hour:date.getHours(),
		minutes:date.getMinutes(),
		seconds:date.getSeconds()
	};
}

const dateParse = (date) => {
	const d = date.slice(0,10).split('-');
	return `${d[2]}.${d[1]}.${d[0]}`;
}

const dateTimeParse = (date) => {
	const dt = date.split('T');
	return (dateParse(dt[0]) + ' ' + (dt.length === 2 ? dt[1] : '')).trim();
}

const dateRound = (ts) => Math.round(ts / 1000);

const reportDateGet = () => {
	const now = new Date();
	let month = now.getMonth() + 1, day = now.getDate();
	month = month > 9 ? month : `0${month}`;
	day = day > 9 ? day : `0${day}`;
	return `${now.getFullYear()}-${month}-${day}`;
}

const r2br = (text) => text.toString().replace('\r\n', '<br/>').replace('\r', '<br/>').replace('\n', '<br/>');

const roundTo = (n, digits) => {
	if (digits === undefined) digits = 0;
	const multiplicator = Math.pow(10, digits);
	n = parseFloat((n * multiplicator).toFixed(11));
	const test = Math.round(n) / multiplicator;
	return +(test.toFixed(digits));
}

const base64 = (data) => Base64.btoa(encodeURIComponent(JSON.stringify(data)));

const numberByWord = (num, ispercent) => {
	const declOfNum = (n, t, o) => (t === '' ? '' : ' ' + t + (n[n.length-2] === '1' ? o[2] : o[[2,0,1,1,1,2,2,2,2,2][n[n.length-1]]]))
	const firstLetterUp = (e) => e[1].toUpperCase() + e.substring(2)
	const t = (k, d) => {
		const e = [
			['',' один',' два',' три',' четыре',' пять',' шесть',' семь',' восемь',' девять'],
			[' десять',' одиннадцать',' двенадцать',' тринадцать',' четырнадцать',' пятнадцать',' шестнадцать',' семнадцать',' восемнадцать',' девятнадцать'],
			['','',' двадцать',' тридцать',' сорок',' пятьдесят',' шестьдесят',' семьдесят',' восемьдесят',' девяносто'],
			['',' сто',' двести',' триста',' четыреста',' пятьсот',' шестьсот',' семьсот',' восемьсот',' девятьсот'],
			['',' одна',' две']
		]
		return e[3][k[0]] + (k[1] === 1 ? e[1][k[2]] : e[2][k[1]] + (d ? e[4][k[2]] : e[0][k[2]]))
	}
	const numLetters = (k, d) => {
		let i = ''
		const e = [
			['','тысяч','миллион','миллиард','триллион','квадриллион','квинтиллион','секстиллион','септиллион','октиллион','нониллион','дециллион'],
			['а','и',''],
			['','а','ов']
		]
		if (k === '' || k === '0') return ' ноль'
		k = k.split(/(?=(?:\d{3})+$)/)
		if (k[0].length === 1) k[0] = '00' + k[0]
		if (k[0].length === 2) k[0] = '0' + k[0]
		for (let j = k.length - 1; j >= 0; j--) {
			if (k[j] !== '000')
				i = (((d && j === (k.length - 1)) || j === (k.length - 2)) && (k[j][2] === '1' || k[j][2] === '2') ? t(k[j],1) : t(k[j])) + declOfNum(k[j], e[0][k.length - 1 - j], (j === (k.length - 2) ? e[1] : e[2])) + i
		}
		return i
	}
	num = Number(num).toFixed(2).split('.')
	return ispercent ? firstLetterUp(numLetters(num[0]) + ' и ' + numLetters(num[1])) :
		firstLetterUp(numLetters(num[0]) + declOfNum(num[0], 'рубл', ['ь','я','ей']) + ' ' + num[1] + declOfNum(num[1], 'копе', ['йка','йки','ек']))
}

const Base64 = {
	chars: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',
	btoa: (input = '')  => {
		let str = input, output = ''
		for (let block = 0, charCode, i = 0, map = Base64.chars; str.charAt(i | 0) || (map = '=', i % 1); output += map.charAt(63 & block >> 8 - i % 1 * 8)) {
			charCode = str.charCodeAt(i += 3/4);
			if (charCode > 0xFF) throw new Error("'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.")
			block = block << 8 | charCode
		}
		return output
	},
	atob: (input= '') => {
		let str = input.replace(/=+$/, ''), output = '';
		if (str.length % 4 === 1) throw new Error("'atob' failed: The string to be decoded is not correctly encoded.")
		for (let bc = 0, bs = 0, buffer, i = 0; buffer = str.charAt(i++); ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer, bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0)
			buffer = Base64.chars.indexOf(buffer)
		return output
	}
}

export {
	phoneNormalize,
	phoneFormatter,
	phoneWS,
	empty,
	moneyFormat,
	dateGet,
	dateNow,
	dateTimeParse,
	timestampGet,
	timestampConvert,
	dateSegmentsGet,
	reportDateGet,
	r2br,
	roundTo,
	base64,
	numberByWord
}
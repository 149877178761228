import Http from '../../Globals/Http';
import {requestOderType} from '../../Globals/Constants';

const get = (id, callback) => Http.get('users', {conditions:[{k:'id',v:id}]}).then((res) => callback(res))

const login = (phone, callback) => Http.request('users', 'login', {conditions:[{k:'phone',v:phone}]}).then((res) => callback(res.data[0]));
const smsCheck = (phone, code, callback) => Http.request('users', 'smsCheck', {conditions:[{k:'phone',v:phone},{k:'code',v:code}],orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data[0]))
const registerCheck = (phone, callback) => Http.request('users', 'registerCheck', {conditions:[{k:'phone',v:phone}]}).then((res) => callback(res.data))

const add = (data, callback) => Http.post('users', {data}).then((res) => callback ? callback(res) : () => {})
const update = (id, data) => Http.put('users', {data,conditions:[{k:'id',v:id}]})

export {
	get,
	add,
	update,
	login,
	smsCheck,
	registerCheck
}
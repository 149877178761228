import React,{Component} from 'react';

// plug-ins
import Modal from 'react-awesome-modal';

// styles
import styles from './Confirm.module.css';

export default class Confirm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			message:props.message,
			modalshow:true
		};
	}
	modalHide = () => this.setState({modalshow:false});
	close = (e) => {
		e.preventDefault();
		this.modalHide();
		this.props.close();
		return false;
	}
	action = () => {
		this.modalHide();
		this.props.action();
	}
	render() {
		if (this.state.message === null) return null;
		return <Modal visible={this.state.modalshow} width={'340'} effect={'fadeInDown'} onClickAway={() => this.close()}>
			<div className={styles.confirmcontainer}>
				<h4>Подтвердите пожалуйста</h4>
				{this.state.message}
				<div className={styles.buttons}>
					<button onClick={this.action.bind(this)}>Да</button>
					<a href="/" className={styles.cancel} onClick={this.close.bind(this)}>Отмена</a>
				</div>
			</div>
		</Modal>
	}
}
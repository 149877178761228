import Http from '../../Globals/Http';

const post = (method, data, callback) => Http.request('soap', method, data).then((res) => callback ? callback(res) : () => {})

const connect = (phone, password, callback) => post('connect', {phone,password}, callback)
const infoGet = (uid, password, callback) => post('getClientInfo', {uid,password}, callback)

const clientRequest = (name, phone, email, birthDay, bornCity, snils, inn, passport, address, addressLive, work, workPosition, salary, photo1, photo2, callback) => post('sendNewClientRequest', {name,phone,email,birthDay,bornCity,snils,inn,passport,address,addressLive,work,workPosition,salary,photo1,photo2}, callback)
const passwordSet = (name, phone, password, callback) => post('openPersonalAccount', {name,phone,password}, callback)

const depositsGet = (callback) => post('getDepositProducts', null, callback)
//const depositSet = (uid, password, sum, expired, expireType, desc, uidSaving, uidProps, phone, callback) => post('sendDepositRequest', {uid,password,sum,expired,expireType,desc,uidSaving,uidProps,phone}, callback)
const depositOperations = (uid, password, uidSession, uidContract, expireDate, callback) => post('getReportDepositContractInfo', {uid,password,uidSession,uidContract,expireDate}, callback)

export {
	connect,
	infoGet,
	clientRequest,
	passwordSet,
	depositsGet,
	//depositSet,
	depositOperations
}
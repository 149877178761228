import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// components
import SimpleTemplate from '../../Components/MasterPages/SimpleTemplate';
import Loading from '../../Components/Loading';

// helpers
import {ls} from '../../Globals/Localstorage';
import {dateGet,base64} from '../../Globals/Utils';

// globals
import {API} from '../../Globals/Constants';

// styles
import styles from './MyDocs.module.css';

class MyDocs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			loading:true,
			menu: [
				{name:'Анкета на вступление в КПК',action:() => this.zayavlenie()},
				{name:'Заявление на вступление в КПК',action:() => this.anketa()},
			]
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user,loading:false});
	}
	zayavlenie = () => {
		const {user} = this.state;
		const data = {
			action:'zayavlenie',
			phone:user.phone,
			email:user.email,
			address:`${user.addressZip}, ${user.address}, ${user.addressHouse}, ${user.addressRoom}`,
			fio:`${user.lastName} ${user.firstName} ${user.middleName}`,
			passport:`${user.passport} выдан ${user.passportIssue} ${dateGet(user.passportIssueDate)} Код подразделения ${user.passportCode}`,
			bornCity:user.bornCity,
			birthDay:dateGet(user.birthDay),
			work:user.work,
			workPosition:user.workPosition
		};
		return this.go(data);
	}
	anketa = () => {
		const {user} = this.state;
		const data = {
			action:'anketa',
			phone:user.phone,
			email:user.email,
			address:`${user.addressZip}, ${user.address}, ${user.addressHouse}, ${user.addressRoom}`,
			firstName:user.firstName,
			lastName:user.lastName,
			middleName:user.middleName,
			passportSeria:user.passport ? user.passport.slice(0,4) : null,
			passportNumber:user.passport ? user.passport.slice(5) : null,
			passportIssue:user.passportIssue,
			passportIssueDate:dateGet(user.passportIssueDate),
			passportCode:user.passportCode,
			bornCity:user.bornCity,
			birthDay:dateGet(user.birthDay),
			inn:user.inn
		};
		return this.go(data);
	}
	go = (data) => `${API.pdf}?data=${base64(data)}`;
	render() {
		if (this.state.loading) return <Loading />;
		return (
			<SimpleTemplate>
				<div className="container">
					<Link to={'/'}><img src={require('../../Images/icons/back.svg')} className="back" alt="назад" /></Link>
					<h2>Мои документы</h2>
					{this.state.menu.map((v, i) => <div key={i}>
						<div className={styles.block}>
							<a href={v.action()} target="_blank" rel="noopener noreferrer">{v.name}</a>
						</div>
					</div>)}
				</div>
			</SimpleTemplate>
		);
	}
}

export default MyDocs;
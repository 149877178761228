import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// components
import SimpleTemplate from '../../Components/MasterPages/SimpleTemplate';

// globals
import {API} from '../../Globals/Constants';

// styles
import styles from './KpkDocs.module.css';

// vars
const menu = [
	{name:'Устав',link:'kpk_ustav.pdf'},
	{name:'Закон о кредитной кооперации',link:'zakon_o_kreditnoj_kooperacii.pdf'},
	{name:'Закон о кредитной кооперации',link:'inn_ogrn.pdf'},
	{name:'Положение о порядке предоставления займов',link:'polozhenie_zajmy.pdf'},
	{name:'Положение о порядке распределения доходов',link:'polozhenie_raspredelenie_dohodov.pdf'},
	{name:'Положение о порядке формирования и использования имущества',link:'polozhenie_formirovanie_imushestva.pdf'},
	{name:'Положение о членстве',link:'polozhenie_chlenstvo.pdf'},
	{name:'Положение об органах КПК',link:'polozhenie_ob_organah.pdf'},
	{name:'Положение об условиях привлечения денежных средств',link:'polozhenie_ob_usloviah_privlechenia_sredstv.pdf'},
	{name:'Базовый стандарт защиты прав и интересов физических и юридических лиц - получателей финансовых услуг, оказываемых членами саморегулируемых организаций в сфере финансового рынка, объединяющих кредитные потребительские кооперативы',link:'basic_standart_kpk.pdf'},
	{name:'Базовый стандарт корпоративного управления кредитного потребительского кооператива',link:'basic_standart_kpk_22122017-4.pdf'},
	{name:'Базовый стандарт совершения кредитным потребительским кооперативом операций на финансовом рынке',link:'bs_perfoming_credit_consumer.pdf'},
	{name:'Полис страхования финансовых рисков',link:'svidetelstvo_nko_povs.pdf'},
	{name:'Выписка из протокола № 19/2018 от 12 сентября 2018 г. заочного заседания совета СРО «Кооперативные Финансы»',link:'vypiska_sro.pdf'},
	{name:'Правила внутреннего контроля от 22 октября 2018 г.',link:'pravila.pdf'},
	{name:'Лицензия НКО ПОВС "ВЗАИМОПОМОЩЬ" на осуществление взаимного страхования от 23 сентября 2019 г.',link:'Лицензия ВС 4354.pdf'},
	{name:'Свидетельство НКО ПОВС "ВЗАИМОПОМОЩЬ" о постановке на налоговый учет от 5 сентября 2019 г.',link:'Свидетельство о постановке на учет.pdf'},
	{name:'Свидетельство НКО ПОВС "ВЗАИМОПОМОЩЬ" о регистрации от 23 декабря 2015 г.',link:'Свидетельство о госрегистрации.png'}
];

class KpkDocs extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}
	linkGet = (link) => `${API.docs}${link}`;
	render() {
		return (
			<SimpleTemplate>
				<div className="container">
					<Link to={'/'}><img src={require('../../Images/icons/back.svg')} className="back" alt="назад" /></Link>
					<h2>Документы КПК</h2>
					{menu.map((v, i) => <div key={i}>
						<div className={styles.block}>
							<a href={this.linkGet(v.link)}>{v.name}</a>
						</div>
					</div>)}
				</div>
			</SimpleTemplate>
		);
	}
}

export default KpkDocs;
import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// components
import SimpleTemplate from '../../Components/MasterPages/SimpleTemplate';
import Confirm from '../../Components/Confirm';
import Loading from '../../Components/Loading';

// models
import {Soap} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';

// styles
import styles from './Dashboard.module.css';

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			alertShow:false
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		const data = ls('data');
		if (data === null) {
			Soap.infoGet(user.uid, user.code, (res) => {
				const data = res.data;
				if (data && data.user) {
					const u = data.user['ФизЛицо'];
					user.snils = u['СНИЛС'];
					user.inn = u['ИНН'];
					user.birthDay = u['ДатаРождения'] ? new Date(u['ДатаРождения']).getTime() / 1000 : 0;
					user.gender = u['Пол'] === 'Мужской' ? 1 : 2;
					user.email = u['ЭлектроннаяПочта'];
					user.work = u['МестоРаботы'];
					const passport = u['Паспорт'] ? u['Паспорт'].split(',') : null;
					if (passport) {
						user.passportFull = u['Паспорт'];
						user.passport = `${passport[1].trim().replace('серия: ','').replace(' ','')} ${passport[2].trim().replace('№ ','')}`;
						user.passportCode = passport[passport.length===7?6:5].replace('код подр. ','').trim();
						user.passportIssue = passport[passport.length===7?5:4].trim();
						user.passportIssueDateStr = passport[3].replace('выдан: ','').trim();
					}
					const address = u['АдресРегистрации'] ? u['АдресРегистрации'].split(',') : null;
					if (address) {
						user.addressFull = u['АдресРегистрации'];
						user.addressZip = address[0].trim();
						user.address = `${address[1].trim()}, ${address[2].trim()}, ${address[3].trim()}`;
						user.addressHouse = address[4].replace('дом № ','').trim();
						user.addressRoom = address[5].replace('квартира ','').trim();
					}
					const addressLive = u['АдресПроживания'] ? u['АдресПроживания'].split(',') : null;
					if (addressLive) {
						user.addressLiveFull = u['АдресПроживания'];
						user.addressZipLive = addressLive[0].trim();
						user.addressLive = `${addressLive[1].trim()}, ${addressLive[2].trim()}, ${addressLive[3].trim()}`;
						user.addressHouseLive = addressLive[4].replace('дом № ','').trim();
						user.addressRoomLive = addressLive[5].replace('квартира ','').trim();
					}
					ls('user', user);
				}
				ls('data', data);
				this.setState({user,data});
			});
		} else this.setState({user,data});
	}
	logoff = (e) => {
		e.preventDefault();
		this.setState({alertShow:true});
		return false;
	}
	confim = () => {
		ls('user', null);
		ls('data', null);
		this.close();
		window.location.href='/';
	}
	close = () => this.setState({alertShow:false});
	render() {
		if (this.state.user === null) return <Loading />;
		return (
			<SimpleTemplate>
				<div className="container">
					<h2>{this.state.user.lastName} {this.state.user.firstName} {this.state.user.middleName}</h2>
					<div className={styles.ctrl}>
						<Link to={'/savings'} className={styles.btn}>Сбережения</Link>
					</div>
					<div className={styles.ctrl}>
						<Link to={'/history'} className={styles.btn}>История операций</Link>
					</div>
					<div className={styles.ctrl}>
						<Link to={'/mydata'} className={styles.btn}>Мои данные</Link>
					</div>
					<div className={styles.ctrl}>
						<Link to={'/mydocs'} className={styles.btn}>Мои документы</Link>
					</div>
					<div className={styles.ctrl}>
						<Link to={'/about'} className={styles.btn}>О нас</Link>
					</div>
					<div className={styles.ctrl}>
						<Link to={'/kpk-docs'} className={styles.btn}>Документы КПК</Link>
					</div>
					<div className={styles.ctrl}>
						<Link to={'/contacts'} className={styles.btn}>Контакты</Link>
					</div>
					<div className={styles.ctrl}>
						<Link to={'/aboutpayment'} className={styles.btn}>Информаци об оплате</Link>
					</div>
					<div className={styles.ctrllast}>
						<a href="/" className={styles.btnouter} onClick={(e) => this.logoff(e)}>Выход</a>
					</div>
				</div>
				{this.state.alertShow && <Confirm message={'Вы уверены что хотите выйти из системы личного кабинета?'} action={this.confim.bind(this)} close={this.close.bind(this)} />}
			</SimpleTemplate>
		);
	}
}

export default Dashboard;
import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// components
import SimpleTemplate from '../../Components/MasterPages/SimpleTemplate';

// helpers
import {phoneFormatter} from '../../Globals/Utils';

// styles
import styles from './Contacts.module.css';

// vars
const contacts = [
	{
		title: 'Казань',
		phone: '+7 (843) 500-51-53',
		phone800: '8 800 500 6983',
		address: 'ул. Декабристов, д. 104',
		worktime: 'пн-сб с 09-00 до 20-00, вс: не работает',
		whatsapp: '79274542126'
	},
	{
		title: 'Зеленодольск',
		phone: '+7 (84371) 546-11',
		phone800: '8 800 500 6983',
		address: 'ул. Комсомольская, 9',
		whatsapp: '79274542126'
	},
	{
		title: 'Казань. Центральный офис',
		phone800: '8 800 500 6983',
		address: 'ул. Лукницкого, д. 2',
	}
];

class Contacts extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}
	waGet = (phone) => `https://wa.me/${phone}`;
	phoneGet = (phone) => `tel:${phone.replace(/\s/gi, '').replace('(', '').replace(')', '').replace(/-/gi, '')}`;
	render() {
		return (
			<SimpleTemplate>
				<div className="container">
					<Link to={'/'}><img src={require('../../Images/icons/back.svg')} className="back" alt="назад" /></Link>
					<h2>Контакты</h2>
					{contacts.map((v, i) => <div key={i}>
						<div className={styles.block}>
							<div><b>{v.title}</b></div>
							<div>
								<div>Телефон</div>
								{v.phone && <div><a href={`${this.phoneGet(v.phone)}`}>{v.phone}</a></div>}
								{v.phone800 && <div><a href={`${this.phoneGet(v.phone800)}`}>{v.phone800}</a> (бесплатно по России)</div>}
							</div>
							{v.whatsapp &&
								<div>
									<div><b>WhatsApp</b></div>
									<a href={`${this.waGet(v.whatsapp)}`}>{phoneFormatter(v.whatsapp)}</a>
								</div>}
							<div>
								<div><b>Адрес</b></div>
								{v.address}
							</div>
							{v.worktime &&
								<div>
									<div><b>Время работы</b></div>
									{v.worktime}
								</div>}
						</div>
					</div>)}
				</div>
			</SimpleTemplate>
		);
	}
}

export default Contacts;
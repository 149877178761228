import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// components
import Alert,{alertShow} from '../../Components/Alert';
import SimpleTemplate from '../../Components/MasterPages/SimpleTemplate';
import Loading from '../../Components/Loading';

// models
import {Soap,Transactions} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {moneyFormat} from '../../Globals/Utils';

// globals
import {API,transactionStatus,transactionType} from '../../Globals/Constants';

// styles
import styles from './SavingTopup.module.css';

class SavingTopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:this.props.match.params.id,
			user:null,
			data:null,
			amount:'',
			loading:true
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user});
		const data = ls('data');
		if (data === null) {
			Soap.infoGet(user.uid, user.code, (res) => {
				if (res.data) this.setState({data:this.dataGet(res.data)}, () => this.setState({loading:false}));
			});
		} else this.setState({data:this.dataGet(data)}, () => this.setState({loading:false}));
	}
	dataGet = (data) => {
		data = data.saving['ИнформацияОДоговореСбережения'];
		return Array.isArray(data)?data:(data?[data]:[]).find(f => f['UIDДоговора'] === this.state.id);
	}
	handleInput = (e) => this.setState({amount:e.target.value});
	create = () => {
		let {amount} = this.state;
		amount = parseInt(amount);
		if (isNaN(amount) || amount < 0) {
			alertShow('Вы ввели неправильное значение суммы!', true);
			return;
		}
		const data = {
			userId:this.state.user.id,
			extUserId:this.state.user.uid,
			objectId:this.state.data['Сбережение']['UIDСбережения'],
			dogovorId:this.state.data['UIDДоговора'],
			amount:amount,
			type:transactionType.SAVING,
			status:transactionStatus.ADD
		};
		Transactions.add(data, (res) => {
			window.location.href = `${API.pay}?id=${res.data.id}`;
		});
	}
	render() {
		if (this.state.loading) return <Loading />;
		return (
			<SimpleTemplate>
				<div className="container">
					<Link to={'/savings'}><img src={require('../../Images/icons/back.svg')} className="back" alt="назад" /></Link>
					<h2>Пополнить счет</h2>
					<h3>{this.state.data['Сбережение']['Наименование']}</h3>
					<div className={styles.column}>
						<div className={styles.row}>
							<b>Процентная ставка</b>
							{this.state.data['Сбережение']['СтавкаПроценты']} % годовых
						</div>
					</div>
					<div className={styles.column}>
						<div className={styles.row}>
							<b>Сумма на счете</b>
							{moneyFormat(this.state.data['ОстатокНоминал'], false)} ₽
						</div>
					</div>
					<div className={styles.column}>
						<div className={styles.row}>
							<b>Сумма пополнения, ₽</b>
							<input type="number" placeholder="Сумма" name="amount" value={this.state.amount} onChange={this.handleInput} />
						</div>
					</div>
					<button className={styles.btn} onClick={() => this.create()} >Пополнить</button>
				</div>
				<Alert />
			</SimpleTemplate>
		);
	}
}

export default SavingTopup;
import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import InputMask from 'react-input-mask';

// components
import Alert,{alertShow} from '../../Components/Alert';
import SimpleTemplate from '../../Components/MasterPages/SimpleTemplate';

// models
import {Users,Soap} from '../../Models';

// helpers
import {empty,phoneNormalize,phoneFormatter,phoneWS} from '../../Globals/Utils';
import {ls} from '../../Globals/Localstorage';

// styles
import styles from './Login.module.css';

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			phone:'',
			sms:'',
			smsBlock:false,
			isPhoneError:undefined,
			isSmsError:undefined
		};
	}
	handlePhone = (e) => {
		const phone = e.target.value;
		if (phone !== '+7 (') this.setState({isPhoneError:empty(phoneNormalize(phone))});
		this.setState({phone});
	};
	handleSMS = (e) => {
		const sms = e.target.value;
		if (sms.length > 4) return;
		this.setState({sms});
	}
	next = () => {
		let {phone,sms,user} = this.state;
		phone = phoneNormalize(phone);
		if (this.state.smsBlock) {
			if (empty(sms)) {
				this.setState({isSmsError:true});
				return;
			}
			Users.smsCheck(phone, this.state.sms, (data) => {
				if (!data) {
					this.setState({isSmsError:true});
					alertShow('Вы ввели неправильный код из СМС!', true);
					return;
				}
				Soap.connect(phoneWS(phone), user.code, (res) => {
					if (!res || empty(res.data)) {
						alertShow('Ваш аккаунт в данный момент находится на рассмотрении модераторами. Мы сообщим вам, как только закончим проверку!', true);
						return
					}
					user.uid = res.data.uid
					user.uidSession = res.data.uidSession
					ls('user', user);
					window.location.href='/dashboard';
				});
			});
		} else {
			if (empty(phone)) {
				this.setState({isPhoneError:true});
				return;
			}
			Users.login(phone, (data) => {
				if (data) {
					this.setState({smsBlock:true,user:data});
					return;
				}
				alertShow('Пользователь с таким номером телефона не зарегистрирован в качестве пайщика!', true);
			})
		}
	}
	render() {
		return (
			<SimpleTemplate>
				<div className="container">
					<Link to={'/'}><img src={require('../../Images/icons/back.svg')} className="back" alt="назад" /></Link>
					{this.state.smsBlock ?
						<>
							<h3>Код из СМС</h3>
							<div className={styles.formColumn}>
								<label>
									<input type="number" iserror={`${this.state.isSmsError!==undefined&&this.state.isSmsError}`} value={this.state.sms} onChange={this.handleSMS} className={styles.inputPanel} />
									<span className={styles.placeholder}>Введите код из СМС</span>
									<span className={styles.hintError}>4 цифры</span>
								</label>
							</div>
						</>
						:
						<>
							<h3>Вход в личный кабинет</h3>
							<div className={styles.formColumn}>
								<label>
									<InputMask type="tel" mask="+7 (999) 999-99-99" maskChar={null} placeholder=" " iserror={`${this.state.isPhoneError!==undefined&&this.state.isPhoneError}`} value={this.state.phone} onChange={this.handlePhone} className={styles.inputPanel} />
									<span className={styles.placeholder}>Номер мобильного телефона</span>
									<span className={styles.hintError}>Пример: +7 (912) 345-67-89</span>
								</label>
							</div>
						</>
					}
					<button onClick={this.next} className={styles.btn}>{this.state.smsBlock ? 'Войти' : 'Далее'}</button>
					{this.state.smsBlock && <div className={styles.info}>
						На номер <b>+{phoneFormatter(this.state.phone)}</b> было отправлено СМС-сообщение с кодом для входа на сайт
					</div>}
				</div>
				<Alert />
			</SimpleTemplate>
		);
	}
}

export default Login;
import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// components
import SimpleTemplate from '../../Components/MasterPages/SimpleTemplate';
import Loading from '../../Components/Loading';

// models
import {Soap} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {moneyFormat} from '../../Globals/Utils';

// styles
import styles from './SavingsAll.module.css';

class SavingsAll extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			data:[],
			loading:true
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user});
		Soap.depositsGet((res) => {
			if (res.data) {
				const data = res.data['СписокСбережений']['Сбережение'];
				this.setState({data:Array.isArray(data)?data:(data?[data]:[])});
			}
			this.setState({loading:false});
		});
	}
	render() {
		if (this.state.loading) return <Loading />;
		return (
			<SimpleTemplate>
				<div className="container">
					<Link to={'/savings'}><img src={require('../../Images/icons/back.svg')} className="back" alt="назад" /></Link>
					<h2>Программы сбережения</h2>
					{this.state.data.length > 0 ?
						this.state.data.map((v, i) => <Link to={`/savingcreate/${v['UIDСбережения']}`} className={styles.block} key={i}>
							<h3 className={styles.savingtitle}>{v['Наименование']}</h3>
							<div className={styles.column}>
								<div className={styles.row}>
									<b>Процентная ставка</b>
									{v['СтавкаПроценты']} % {v['ЕдИзмСрока']}
								</div>
							</div>
							<div className={styles.column}>
								<div className={styles.row}>
									<b>Сумма</b>
									от {moneyFormat(v['СуммаМин'], false)} до {moneyFormat(v['СуммаМакс'], false)} ₽
								</div>
							</div>
							<div className={styles.column}>
								<div className={styles.row}>
									<b>Срок</b>
									от {v['СрокМин']||'1'} до {v['СрокМакс']||'12'} {v['ЕдИзмСрока']}
								</div>
							</div>
						</Link>)
					:
						<div>Программы сбережения не найдены</div>
					}
				</div>
			</SimpleTemplate>
		);
	}
}

export default SavingsAll;
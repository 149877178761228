import React from 'react';
import ReactDOM from 'react-dom';

// components
import App from './App';
import * as serviceWorker from './serviceWorker';

// models
import { Logs } from "./Models";

// styles
import './index.css';

// main
ReactDOM.render(<App />, document.getElementById('root'));

// workers
serviceWorker.unregister();

// global errors
window.onerror = function() {
	Logs.add(arguments);
}
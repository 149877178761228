import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// components
import SimpleTemplate from '../../Components/MasterPages/SimpleTemplate';
import Loading from '../../Components/Loading';

// models
import {Transactions} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {dateGet,moneyFormat} from '../../Globals/Utils';

// globals
import {transactionStatusName,transactionStatus,transactionTypeName} from '../../Globals/Constants';

// styles
import styles from './History.module.css';

class History extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		Transactions.get(user.id, (res) => this.setState({user,data:res.data}));
	}
	render() {
		if (this.state.user === null) return <Loading />;
		return (
			<SimpleTemplate>
				<div className="container">
					<Link to={'/'}><img src={require('../../Images/icons/back.svg')} className="back" alt="назад" /></Link>
					<h2>История операций</h2>
					{this.state.data.length > 0 ?
					<table>
						<thead>
							<tr>
								<th>Дата</th>
								<th>Описание</th>
								<th>Сумма, ₽</th>
							</tr>
						</thead>
						<tbody>
							{this.state.data.map((v,i) => <tr key={i}>
								<td>{dateGet(v.dateCreate, {showTime:true})}</td>
								<td>{transactionTypeName[v.type]}</td>
								<td className={styles.nowrap}>
									{moneyFormat(v.amount)}
									<span className={v.status===transactionStatus.ADD?styles.red:styles.green}>{transactionStatusName[v.status]}</span>
								</td>
							</tr>)}
						</tbody>
					</table>
					:
					<div>Операции не найдены</div>
					}
				</div>
			</SimpleTemplate>
		);
	}
}

export default History;
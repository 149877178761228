import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import InputMask from 'react-input-mask';

// components
import Alert,{alertShow} from '../../Components/Alert';
import SimpleTemplate from '../../Components/MasterPages/SimpleTemplate';
import Loading from '../../Components/Loading';

// models
import {Soap,Users,Transactions} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {dateGet,moneyFormat,roundTo,base64,numberByWord, phoneNormalize} from '../../Globals/Utils';

// globals
import {API,transactionType,transactionStatus} from '../../Globals/Constants';

// styles
import styles from './SavingCreate.module.css';

class SavingCreate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:this.props.match.params.id,
			saving:null,
			data:null,
			amount:null,
			months:null,
			user:null,
			sms:'',
			loading:true,
			isstart:true,
			isdogovorshow:false,
			issms:false,
			isSmsError:undefined
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user});
		Soap.depositsGet((res) => {
			if (res.data) {
				let data = res.data['СписокСбережений']['Сбережение'];
				data = (Array.isArray(data) ? data : (data ? [data] : [])).find(f => f['UIDСбережения'] === this.state.id);
				const d = {
					uidSaving:data['UIDСбережения'],
					uidProps:data['Код'],
					minMonths:data['СрокМин'],
					maxMonths:data['СрокМакс'],
					minAmount:data['СуммаМин'],
					maxAmount:data['СуммаМакс'],
					percent:data['СтавкаПроценты'],
					ext:data['ЕдИзмСрока'],
					title:data['Наименование']
				}
				this.setState({data:d,months:data['СрокМин'],amount:data['СуммаМин']}, () => this.calc());
			}
			this.setState({loading:false});
		});
	}
	amountSet = (e) => this.setState({amount:e.target.value}, () => this.calc());
	monthsSet = (e) => this.setState({months:e.target.value}, () => this.calc());
	calc = () => {
		const months = parseInt(this.state.months),
			monperc = parseFloat(this.state.data.percent / 12);
		let sumitog = 0, itogo = 0, t = 10000;
		for (let i = 1; i <= months; i++) {
			const sum = parseFloat((monperc / 100) * (parseFloat(this.state.amount) + sumitog));
			itogo = roundTo((parseFloat(sum) * t) / t, 2);
			sumitog = sumitog + itogo;
		}
		sumitog = roundTo((parseFloat(sumitog) * t) / t, 2);
		this.setState({totaldelta:sumitog,total:roundTo(((sumitog + parseFloat(this.state.amount)) * t) / t, 2)});
	}
	create = () => {
		const {amount,months} = this.state, {minAmount,maxAmount,minMonths,maxMonths,percent,title} = this.state.data;
		if (amount < minAmount || amount > maxAmount) {
			alertShow('Вы ввели неправильное значение суммы!', true);
			return;
		}
		if (parseInt(months) < parseInt(minMonths) || parseInt(months) > parseInt(maxMonths)) {
			alertShow('Вы ввели неправильное значение срока!', true);
			return;
		}
		const saving = {
			amount,
			months,
			percent,
			title,
			minAmount,
			maxAmount,
			minMonths,
			maxMonths,
			ext:this.state.data.ext,
			uidSaving:this.state.data.uidSaving,
			uidProps:this.state.data.uidProps
		}
		this.setState({saving});
		this.setState({isstart:false,isdogovorshow:true,issms:false})
	}
	dogovorUrl = () => {
		const dt = new Date()
		const {user,saving} = this.state, d = {
			action:'dogovor',
			inn:user.inn,
			fio:`${user.lastName} ${user.firstName} ${user.middleName}`,
			fioInitials:`${user.lastName} ${user.firstName[0].toUpperCase()}.${user.middleName[0].toUpperCase()}.`,
			bornCity:user.bornCity,
			birthDay:dateGet(user.birthDay),
			passportSeria:user.passport.slice(0,4),
			passportNumber:user.passport.slice(5),
			passportIssue:user.passportIssue,
			passportIssueDate:dateGet(user.passportIssueDate),
			passportCode:user.passportCode,
			address:`${user.addressZip}, ${user.address}, ${user.addressHouse}, ${user.addressRoom}`,
			addressLive:`${user.addressZipLive}, ${user.addressLive}, ${user.addressHouseLive}, ${user.addressRoomLive}`,
			amount:saving.amount,
			amountWord:numberByWord(saving.amount),
			percent:saving.percent,
			percentWord:numberByWord(saving.percent, true),
			duration:`${saving.months} ${saving.ext}`,
			program:saving.title,
			dateEnd:dateGet(Math.round(new Date(dt.setMonth(dt.getMonth() + 6)).getTime() / 1000))
		}
		return `${API.pdf}?data=${base64(d)}`
	}
	accept = () => this.setState({isstart:false,isdogovorshow:false,issms:true});
	handleSMS = (e) => this.setState({sms:e.target.value});
	save = () => {
		const {user,saving} = this.state, phone = phoneNormalize(user.phone);
		Users.smsCheck(phone, this.state.sms, (data) => {
			if (!data) {
				this.setState({isSmsError:true});
				alertShow('Вы ввели неправильный код из СМС!', true);
				return;
			}
			const transaction = {
				userId:user.id,
				extUserId:user.uid,
				objectId:saving.uidSaving,
				amount:saving.amount,
				duration:saving.months,
				type:transactionType.SAVING_NEW,
				status:transactionStatus.ADD
			}
			Transactions.add(transaction, (res) => {
				window.location.href = `${API.pay}?id=${res.data.id}`;
			});
		});
	}
	render() {
		if (this.state.loading) return <Loading />;
		return (
			<SimpleTemplate>
				{this.state.isstart &&
					<div className="container">
						<Link to={'/savings'}><img src={require('../../Images/icons/back.svg')} className="back" alt="назад" /></Link>
						<h2>{this.state.data.title}</h2>
						<div className={styles.column}>
							<div className={styles.row}>
								<b>Годовая процентная ставка</b>
								<div>{this.state.data.percent} %</div>
							</div>
						</div>
						<div className={styles.column}>
							<div className={styles.row}>
								<b>Сумма, ₽</b>
								<input type="number" placeholder="Сумма" name="amount" value={this.state.amount} onChange={this.amountSet} />
								<div>Минимальная сумма {moneyFormat(this.state.data.minAmount, false)} ₽, максимальная сумма {moneyFormat(this.state.data.maxAmount, false)} ₽</div>
							</div>
						</div>
						<div className={styles.column}>
							<div className={styles.row}>
								<b>Срок сбережения, {this.state.data.ext}</b>
								<input type="number" placeholder="Сумма" name="months" value={this.state.months} onChange={this.monthsSet} />
								<div>Минимальный срок размещения {this.state.data.minMonths} {this.state.data.ext}, максимальный срок размещения {this.state.data.maxMonths} {this.state.data.ext}</div>
							</div>
						</div>
						<div className={styles.column}>
							<div className={styles.row}>
								<b>Сумма возврата</b>
								{moneyFormat(this.state.total)} ₽
							</div>
							<div className={styles.row}>
								<b>Ваша прибыль</b>
								{moneyFormat(this.state.totaldelta)} ₽
							</div>
						</div>
						<button className={styles.btn} onClick={() => this.create()}>Оформить договор сбережения</button>
					</div>}
				{this.state.isdogovorshow &&
					<div className={styles.container}>
						<h2>Договор сбережения</h2>
						<h4><a href={this.dogovorUrl()} target="_blank" rel="noopener noreferrer">Договор сбережения</a></h4>
						<div>(откроется в новом окне)</div>
						<button className={styles.btn} onClick={() => this.accept()}>Подписать договор</button>
					</div>}
				{this.state.issms &&
					<div className={styles.container}>
						<h2>Код из СМС</h2>
						<div className={styles.formColumn}>
							<label>
								<InputMask type="text" mask="9999" maskChar={null} placeholder=" " iserror={`${this.state.isSmsError!==undefined&&this.state.isSmsError}`} value={this.state.sms} onChange={this.handleSMS} className={styles.inputPanel} />
								<span className={styles.placeholder}>Введите код из СМС</span>
								<span className={styles.hintError}>4 цифры</span>
							</label>
						</div>
						<button className={styles.btn} onClick={() => this.save()}>Продолжить</button>
					</div>}
				<Alert />
			</SimpleTemplate>
		);
	}
}

export default SavingCreate;
import React from 'react';
import {BrowserRouter,Switch,Route,Redirect} from 'react-router-dom';

// screens
import Start				from './Screens/Start';
import About				from './Screens/About';
import Contacts				from './Screens/Contacts';
import KpkDocs				from './Screens/KpkDocs';
import AboutPayment			from './Screens/AboutPayment';
import MyData				from './Screens/MyData';
import MyDocs				from './Screens/MyDocs';
import Login				from './Screens/Login';
import Register				from './Screens/Register';
import RegisterFill			from './Screens/RegisterFill';
import Dashboard			from './Screens/Dashboard';
import History				from './Screens/History';
import Savings				from './Screens/Savings';
import SavingsAll			from './Screens/SavingsAll';
import SavingHistory		from './Screens/SavingHistory';
import SavingTopup			from './Screens/SavingTopup';
import SavingCreate			from './Screens/SavingCreate';
import CreditCreate			from './Screens/CreditCreate';
import Error				from './Screens/Error';

// helpers
import {ls}					from './Globals/Localstorage';

// start
const App = () => {
	return (
		<BrowserRouter>
			<Switch>
				<Route path='/' exact render={() => <Redirect to={ls('user') === null ? "/start" : "/dashboard"} />} />
				<Route path='/start' component={Start} exact />
				<Route path='/about' component={About} exact />
				<Route path='/contacts' component={Contacts} exact />
				<Route path='/kpk-docs' component={KpkDocs} exact />
				<Route path='/aboutpayment' component={AboutPayment} exact />
				<Route path='/mydata' component={MyData} exact />
				<Route path='/mydocs' component={MyDocs} exact />
				<Route path='/login' component={Login} exact />
				<Route path='/register' component={Register} exact />
				<Route path='/registerfill' component={RegisterFill} exact />
				<Route path='/dashboard' component={Dashboard} exact />
				<Route path='/history' component={History} exact />
				<Route path='/savings' component={Savings} exact />
				<Route path='/savingsall' component={SavingsAll} exact />
				<Route path='/savinghistory/:id' component={SavingHistory} exact />
				<Route path='/savingtopup/:id' component={SavingTopup} exact />
				<Route path='/savingcreate/:id' component={SavingCreate} exact />
				<Route path='/creditcreate' component={CreditCreate} exact />
				<Route path='/error' component={Error} status={500} exact />
				<Route path='/error401' component={Error} status={401} exact />
				<Route path='/error403' component={Error} status={403} exact />
				<Route path='/error404' component={Error} status={404} exact />
				<Route path='*' component={Error} status={404} />
			</Switch>
		</BrowserRouter>
	);
}

export default App;
import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// components
import SimpleTemplate from '../../Components/MasterPages/SimpleTemplate';
import Loading from '../../Components/Loading';

// models
import {Soap} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {reportDateGet,moneyFormat,phoneWS,empty} from '../../Globals/Utils';

// styles
import styles from './SavingHistory.module.css';

class SavingHistory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:this.props.match.params.id,
			user:null,
			data:[],
			loading:true
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user});
		this.dataGet(user, this.state.id, (res) => {
			if (res.data) {
				this.setState({loading:false,data:res.data.report});
				return;
			}
			Soap.connect(phoneWS(user.phone), user.code, (res) => {
				if (!res || empty(res.data)) {
					window.location.href='/error403';
					return
				}
				user.uid = res.data.uid;
				user.uidSession = res.data.uidSession;
				ls('user', user);
				this.dataGet(user, this.state.id, (res) => this.setState({loading:false,user,data:res.data?res.data.report:[]}));
			})
		});
	}
	dataGet = (user, contract, callback) => Soap.depositOperations(user.uid, user.code, user.uidSession, contract, reportDateGet(), (res) => callback ? callback(res) : {})
	render() {
		if (this.state.loading) return <Loading />;
		return (
			<SimpleTemplate>
				<div className="container">
					<Link to={'/savings'}><img src={require('../../Images/icons/back.svg')} className="back" alt="назад" /></Link>
					<h2>Операции</h2>
					{this.state.data.length > 0 ?
					<table>
						<thead>
							<tr>
								<th>Дата</th>
								<th>Описание</th>
								<th>Сумма, ₽</th>
							</tr>
						</thead>
						<tbody>
							{this.state.data.map((v,i) => <tr key={i}>
								<td>{v.date}</td>
								<td>{v.name}</td>
								<td className={styles.nowrap}>{moneyFormat(v.amount)}</td>
							</tr>)}
						</tbody>
					</table>
					:
					<div>Операции не найдены</div>
					}
				</div>
			</SimpleTemplate>
		);
	}
}

export default SavingHistory;
import React from 'react';
import {Link} from 'react-router-dom';

// components
import SimpleTemplate from '../../Components/MasterPages/SimpleTemplate';

// helpers
import {empty} from '../../Globals/Utils';

// styles
import styles from './Error.module.css';

const Error = () => {
	let page = window.location.pathname,
		title = 'Ошибка 404. Страница не найдена!',
		text = <>
			<p>Наверное, вы ошиблись при наборе адреса или перешли по неверной ссылке.</p>
			<p>
				Не расстраивайтесь, решение всегда найдется!<br/>
				Перейдите на <Link to={'/'}>главную страницу</Link> или посмотрите информацию о <Link to={'/about'}>о нас</Link>.
			</p>
		</>;
	if (!empty(page)) page = page.replace('/', '');
	switch(page) {
		case 'error':
			title = 'Ошибка 500. Ошибка сервера';
			text = <>
				<p>На сервере произошла ошибка. Попробуйте обновить страницу.</p>
				<p>
					Пожалуйста подождите, она скоро будет исправлена!<br/>
					Перейдите на <Link to={'/'}>главную страницу</Link> или посмотрите информацию о <Link to={'/about'}>о нас</Link>.
				</p>
			</>;
			break;
		case 'error401':
			title = 'Ошибка 401. Требуется авторизация';
			text = <>
				<p>У вас нет прав для доступа к этому разделу сайта!</p>
				<p>
					Вероятно вы не зарегистрированы на сайте либо не вошли под своей учетной записью.<br/>
					Перейдите на <Link to={'/'}>главную страницу</Link> или посмотрите информацию о <Link to={'/about'}>о нас</Link>.
				</p>
			</>;
			break;
		case 'error403':
			title = 'Ошибка 403. Отказано в доступе';
			text = <>
				<p>Вы запросили страницу, доступ к которой ограничен специальными условиями доступа.</p>
				<p>Перейдите на <Link to={'/'}>главную страницу</Link> или посмотрите информацию о <Link to={'/about'}>о нас</Link>.</p>
			</>;
			break;
		default: break;
	}
	return (
		<SimpleTemplate title={title}>
			<div className="container">
				<img src={require('../../Images/logo.svg')} className={styles.logo} alt="" />
				<div className={styles.errorinfo}>
					{text}
				</div>
			</div>
		</SimpleTemplate>
	);
}

export default Error;
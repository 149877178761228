import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// components
import SimpleTemplate from '../../Components/MasterPages/SimpleTemplate';
import Loading from '../../Components/Loading';

// models
import {Soap} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {dateTimeParse,moneyFormat} from '../../Globals/Utils';

// styles
import styles from './Savings.module.css';

class Savings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			data:[],
			loading:true
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user});
		const data = ls('data');
		if (data === null) {
			Soap.infoGet(user.uid, user.code, (res) => {
				if (res.data) this.setState({data:this.dataGet(res.data)}, () => this.setState({loading:false}));
			});
		} else this.setState({data:this.dataGet(data)}, () => this.setState({loading:false}));
	}
	dataGet = (data) => {
		data = data.saving['ИнформацияОДоговореСбережения'];
		return Array.isArray(data)?data:(data?[data]:[]);
	}
	render() {
		if (this.state.loading) return <Loading />;
		return (
			<SimpleTemplate>
				<div className="container">
					<Link to={'/'}><img src={require('../../Images/icons/back.svg')} className="back" alt="назад" /></Link>
					<h2>Мои сбережения</h2>
					{this.state.data.length > 0 ?
						this.state.data.map((v, i) => <div className={styles.block} key={i}>
							<h3 className={styles.savingtitle}>{v['Сбережение']['Наименование']}</h3>
							<div className={styles.column}>
								<div className={styles.row}>
									<b>Процентная ставка</b>
									{v['Сбережение']['СтавкаПроценты']} % годовых
								</div>
							</div>
							<div className={styles.column}>
								<div className={styles.row}>
									<b>Сумма на счете</b>
									{moneyFormat(v['ОстатокНоминал'], false)} ₽
								</div>
								<div className={styles.row}>
									<b>Сумма договора</b>
									{moneyFormat(v['СуммаДоговора'], false)} ₽
								</div>
							</div>
							<div className={styles.column}>
								<div className={styles.row}>
									<b>Заключение договора</b>
									{dateTimeParse(v['ДатаДоговора'])}
								</div>
							</div>
							<div className={styles.column}>
								<div className={styles.row}>
									<b>Окончание договора</b>
									{dateTimeParse(v['ДатаОкончания'])}
								</div>
								<div className={styles.row}>
									<b>Срок договора</b>
									{v['СрокДоговора']} мес.
								</div>
							</div>
							<div className={styles.ctrl}>
								<Link to={`/savingtopup/${v['UIDДоговора']}`} className={styles.btn}>Пополнить</Link>
								<Link to={`/savinghistory/${v['UIDДоговора']}`} className={styles.btnouter}>Операции</Link>
							</div>
						</div>)
					:
						<div>Сбережения не найдены</div>
					}
					<Link to={'/savingsall'} className={styles.btn}>Программы сбережения</Link>
				</div>
			</SimpleTemplate>
		);
	}
}

export default Savings;
import React from 'react';

// styles
import styles from './Loading.module.css';

const Loading = () => {
	return (
		<div className={styles.loadingContainer}>
			<div>
				<img src={require('../../Images/loading.svg')} alt=" окно" />
			</div>
		</div>
	);
}
export default Loading;
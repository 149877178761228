const isDeveloperMode = process.env.NODE_ENV !== 'production';
const url = isDeveloperMode ? '//localhost' : '//kpkesc.pavit.ru';
const API = {
	url			: `${url}/api`,
	assets		: `${url}/assets`,
	pay			: `${url}/payment/`,
	pdf			: `${url}/docs/`,
	docs		: 'https://kpkesc.ru/!files/',
	key			: '7c9e9b14-2937-43c1-9eb2-6c9724ce43cf'
};

const MAPS = {
	geoKey			: 'e9800f22-c2da-48d3-80e6-c6ed93869f33',
	urlGeoCodes		: (address) => `https://geocode-maps.yandex.ru/1.x/?format=json&apikey=${MAPS.geoKey}&results=20&country=ru&geocode=${address}`
}

const requestConditionType = {
	EQUAL		: 0,
	NOT_EQUAL	: 1,
	LESS		: 2,
	MORE		: 3,
	IS_NULL		: 4,
	NOT_NULL	: 5,
	LIKE		: 6
};

const requestConcatinationType = {
	AND			: 0,
	OR			: 1
};

const requestOderType = {
	ASC			: 0,
	DESC		: 1
};

const userStatus = {
	ACTIVE		: 0,
	BAN			: 1,
	DELETE		: 2
};
const userStatusName = ['активен','забанен','удален'];

export {
	url,
	API,
	MAPS,
	requestConditionType,
	requestConcatinationType,
	requestOderType,
	userStatus,
	userStatusName
};

export const transactionType = {
	MEMBER			: 0,
	SHAREHOLDER		: 1,
	SAVING			: 2,
	SAVING_NEW		: 3,
	LOAN			: 4
};
export const transactionTypeName = ['Членский взнос','Паевый взнос','Пополнение сбережения','Договор сбережения','Оплата займа'];

export const transactionStatus = {
	ADD			: 0,
	FINISH		: 1
};
export const transactionStatusName = ['Не оплачено','Оплачено'];
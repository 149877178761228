import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// components
import SimpleTemplate from '../../Components/MasterPages/SimpleTemplate';

// styles
import styles from './Start.module.css';

class Start extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}
	render() {
		return (
			<SimpleTemplate>
				<div className="container">
					<img src={require('../../Images/logo.svg')} className={styles.logo} alt="" />
					<div className={styles.ctrl}>
						<Link to={'/login'} className={styles.btn}>Я уже клиент</Link>
					</div>
					<div className={styles.ctrl}>
						<Link to={'/register'} className={styles.btn}>Новый клиент</Link>
					</div>
					<div className={styles.ctrl}>
						<Link to={'/creditcreate'} className={styles.btn}>Заявка на кредит</Link>
					</div>
				</div>
			</SimpleTemplate>
		);
	}
}

export default Start;
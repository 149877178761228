import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// components
import SimpleTemplate from '../../Components/MasterPages/SimpleTemplate';
import Loading from '../../Components/Loading';

// helpers
import {ls} from '../../Globals/Localstorage';
import {dateGet,moneyFormat,phoneFormatter} from '../../Globals/Utils';

// styles
import styles from './MyData.module.css';

class MyData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			loading:true
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user,loading:false});
	}
	render() {
		if (this.state.loading) return <Loading />;
		return (
			<SimpleTemplate>
				<div className="container">
					<Link to={'/'}><img src={require('../../Images/icons/back.svg')} className="back" alt="назад" /></Link>
					<h2>Мои данные</h2>
					<div className={styles.row}>
						<span>Фамилия</span>
						<div><b>{this.state.user.lastName}</b></div>
					</div>
					<div className={styles.row}>
						<span>Имя</span>
						<div><b>{this.state.user.firstName}</b></div>
					</div>
					<div className={styles.row}>
						<span>Отчество</span>
						<div><b>{this.state.user.middleName}</b></div>
					</div>
					<div className={styles.row}>
						<span>Серия паспорта</span>
							<div><b>{this.state.user.passport ? this.state.user.passport.slice(0,4) : 'нет данных'}</b></div>
					</div>
					<div className={styles.row}>
						<span>Номер паспорта</span>
						<div><b>{this.state.user.passport ? this.state.user.passport.slice(5) : 'нет данных'}</b></div>
					</div>
					<div className={styles.row}>
						<span>Кем выдан</span>
						<div><b>{this.state.user.passportIssue || 'нет данных'}</b></div>
					</div>
					<div className={styles.row}>
						<span>Дата выдачи</span>
						<div><b>{this.state.user.passportIssueDate ? dateGet(this.state.user.passportIssueDate) : (this.state.user.passportIssueDateStr || 'нет данных')}</b></div>
					</div>
					<div className={styles.row}>
						<span>Код подразделения</span>
						<div><b>{this.state.user.passportCode || 'нет данных'}</b></div>
					</div>
					<div className={styles.row}>
						<span>Адрес проживания</span>
						<div><b>{this.state.user.addressLive ? `${this.state.user.addressZipLive}, ${this.state.user.addressLive}, д.${this.state.user.addressHouseLive}, кв.${this.state.user.addressRoomLive}` : 'нет данных'}</b></div>
					</div>
					<div className={styles.row}>
						<span>Адрес регистрации</span>
						<div><b>{this.state.user.address ? `${this.state.user.addressZip}, ${this.state.user.address}, д.${this.state.user.addressHouse}, кв.${this.state.user.addressRoom}` : 'нет данных'}</b></div>
					</div>
					<div className={styles.row}>
						<span>ИНН</span>
						<div><b>{this.state.user.inn || 'нет данных'}</b></div>
					</div>
					<div className={styles.row}>
						<span>СНИЛС</span>
						<div><b>{this.state.user.snils || 'нет данных'}</b></div>
					</div>
					<div className={styles.row}>
						<span>Дата рождения</span>
						<div><b>{this.state.user.birthDay ? dateGet(this.state.user.birthDay) : 'нет данных'}</b></div>
					</div>
					<div className={styles.row}>
						<span>Место рождения</span>
						<div><b>{this.state.user.bornCity || 'нет данных'}</b></div>
					</div>
					<div className={styles.row}>
						<span>Место работы</span>
						<div><b>{this.state.user.work || 'нет данных'}</b></div>
					</div>
					<div className={styles.row}>
						<span>Должность</span>
						<div><b>{this.state.user.workPosition || 'нет данных'}</b></div>
					</div>
					<div className={styles.row}>
						<span>Заработная плата</span>
						<div><b>{this.state.user.salary ? (moneyFormat(this.state.user.salary, false) + ' ₽') : 'нет данных'}</b></div>
					</div>
					<div className={styles.row}>
						<span>Мобильный телефон</span>
						<div><b>{phoneFormatter(this.state.user.phone)}</b></div>
					</div>
					<div className={styles.row}>
						<span>Адрес электронной почты</span>
						<div><b>{this.state.user.email || 'нет данных'}</b></div>
					</div>
				</div>
			</SimpleTemplate>
		);
	}
}

export default MyData;